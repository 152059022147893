<template>
	<div>
		<hr class="thicker-radius" />
		<div id="change-email-container">
			<h2>{{ languageStrings.editEmail }}</h2>
			<div class="input-container">
				<div v-if="serverBusy" class="loading">
					<span class="loading-icon"></span>
					<span class="loading-message">{{ busyText }}</span>
				</div>
				<label for="changeEmail">{{
					emailAddress?.length > 0 ? languageStrings.currentEmail : languageStrings.noEmailOnFile
				}}</label>
				<input
					type="text"
					id="changeEmail"
					name="changeEmail"
					@keydown.enter="changeEmailAddress()"
					@input="setButtonState()"
					v-model="emailAddress"
					:placeholder="emailAddress?.length > 0 ? emailAddress : languageStrings.enterEmailAddress"
					v-on:keydown.enter.prevent
					class="curved-border encore-color extra-padding-for-name-email"
				/>
				<p>{{ languageStrings.emailIsOptional }}</p>
				<!-- We need "keydown.enter.prevent" to stop ENTER key from attempting submit. Tries to submit to "/?phoneNumber=" -->
			</div>
			<h2
				class="warn"
				v-if="
					userProfile?.personalInfo?.emailVerificationStatus === 'Unverified' &&
					systemSettings.features.allowEmailVerification
				"
			>
				{{ languageErrorStrings.emailNotVerified }}
			</h2>
			<div class="button-container">
				<button
					v-if="showSubmitButton"
					class="new-encore-button btn"
					type="submit"
					title="Save Changes"
					@click="changeEmailAddress()"
				>
					{{ languageStrings.saveEmail }}
				</button>
				<button
					v-if="
						userProfile?.personalInfo?.emailVerificationStatus === 'Unverified' &&
						systemSettings.features.allowEmailVerification &&
						!showSubmitButton
					"
					class="btn"
					type="submit"
					:title="languageStrings.sendVerificationEmail"
					@click="sendVerificationEmail()"
				>
					{{ languageStrings.sendVerificationEmail }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";

export default {
	name: "ChangeUserEmail",
	props: {
		playerState: Object,
		userProfile: Object,
		systemSettings: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			busyText: "",
			serverBusy: false,
			showSubmitButton: false,
			emailAddress: this?.userProfile?.personalInfo?.emailAddress,
		};
	},
	watch: {
		userProfile: {
			handler() {
				this.emailVerificationStatus = this?.userProfile?.personalInfo.emailAddress;
				this.emailAddress = this?.userProfile?.personalInfo.emailAddress;
			},
			deep: true,
		},
	},
	created() {},
	mounted() {
		//this line causes the edit user profile page to call the server multiple times(almost infinite till you run out of memory) when you refresh the edit page
		//everything appears to still work after testing and removing the line
		// this.eventBus.emit("updateUserProfile");
		this.emailAddress = this?.userProfile?.personalInfo?.emailAddress;
		this.emailVerificationStatus = this?.userProfile?.personalInfo?.emailVerificationStatus;
	},
	methods: {
		setButtonState() {
			this.showSubmitButton = this.playerState?.email !== this.emailAddress;
		},
		async sendVerificationEmail() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.sendingVerificationEmail;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let requestUrl = new URL("/api/v1/user/verification/email/code", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.sendVerificationEmailUnsuccessful;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				this.status.ok = true;
				this.status.message = this.languageErrorStrings.verificationEmailsuccessfull;
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateUserProfile");
				this.emailAddress = this.userProfile?.personalInfo.emailAddress;
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
		async changeEmailAddress() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.savingEmailAddress;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				userId: this.playerState.userId,
				userProfile: {
					personalInfo: {
						emailAddress: this.emailAddress,
					},
				},
			};

			let requestUrl = new URL("/api/v1/user/profile", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message =
						this.languageErrorStrings.somethingWentWrongConnectingToServerTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = `${this.languageErrorStrings.attemptingToChangeEmailAddressWasNotSuccessful}.  ${dataJson?.status}`;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				let newPlayerState = this.playerState;

				newPlayerState.emailAddress = dataJson.userProfile.personalInfo.emailAddress;
				this.emailVerificationStatus = dataJson.userProfile.personalInfo.emailVerificationStatus;

				this.showSubmitButton = false;
				this.status.ok = true;
				this.status.message = this.languageErrorStrings.emailAddressChangedSuccessfully;
				this.eventBus.emit("updatePlayerState", newPlayerState);
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading {
	display: grid;
	align-content: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	font-weight: bold;
	background-color: rgb(0 0 0 / 70%);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	z-index: 100;
}

.loading-message {
	text-align: center;
	display: block;
	margin: 15px;
}

#change-email-container {
	display: grid;
	align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10001;
	color: #fff;
	overflow: hidden auto;
	text-align: center;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	#change-name-container {
		margin-bottom: 100px;
	}
}

#change-name-container > div {
	margin: auto;
	width: 50vw;
}

#change-name-container fieldset {
	padding: 0;
}

.input-container,
.button-container {
	display: flex;
	max-width: 20em;
	align-items: center;
	margin: auto;
}

.button-container {
	flex-flow: row nowrap;
}

.input-container {
	flex-direction: column;
}

.btn {
	margin: 0px 5px 5px;
}

input {
	width: 85%;
}

@media (min-width: 768px) {
	#change-name-container > div {
		/* padding: 30px; */
		width: 50%;
	}
}

@media (max-width: 767px) {
	input {
		width: 75% !important;
	}
}
</style>
